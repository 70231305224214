import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MediaSelector from '../MediaSelector'; // Medya seçici bileşenini import edin
import LargeCardSection from '../../UI/sections/LargeCardSection'; // LargeCardSection bileşenini import edin
import { S3File } from '../../services/types'; // S3File tipini import edin

interface LargeCardFormProps {
    onSave: (data: any) => void;
}

const LargeCardForm: React.FC<LargeCardFormProps> = ({ onSave }) => {
    const { id } = useParams<{ id: string }>(); // DesignPage'den gelen ID'yi burada kullanıyoruz
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false); // Modal açık mı kapalı mı?
    const [selectedMedia, setSelectedMedia] = useState<S3File | null>(null); // Seçilen medya dosyası
    const [url, setUrl] = useState(''); // Manuel olarak girilen URL
    const [error, setError] = useState(''); // Hata mesajı için state
    const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Pop-up önizleme state

    const handleSave = async () => {
        if (!selectedMedia || !url) {
            setError('Hem medya hem de URL girilmelidir.'); // Zorunlu alanlar için hata mesajı
            return;
        }

        const componentData = {
            designId: id, // DesignPage'deki ID'yi burada kullanıyoruz
            componentName: 'Large Card', // Component adı sabit
            componentData: { media: selectedMedia.url, url }, // Seçilen medya dosyası ve URL birlikte kaydedilir
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/component`, componentData);
            onSave(response.data); // Backend'den dönen veriyi onSave ile ilet
          
        } catch (error) {
            console.error('Veri kaydedilirken bir hata oluştu:', error);
        }
    };

    const handleMediaSelect = (file: S3File) => {
        setSelectedMedia(file);
        setIsMediaSelectorOpen(false);
        setError(''); // Medya seçildiğinde hata mesajı temizlenir
    };

    const handlePreview = () => {
        setIsPreviewOpen(true); // Pop-up'ı aç
    };

    // Medya türüne göre doğru medya gösterimini belirleyen fonksiyon
    const renderMediaPreview = (media: S3File) => {
        const fileExtension = media.url.split('.').pop()?.toLowerCase();
    
        if (['jpg', 'png', 'jpeg', 'webp', 'gif'].includes(fileExtension || '')) {
            // Görsel dosyaları için
            return (
                <>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <img src={media.url} alt={media.key} className="max-w-full max-h-64 object-contain mx-auto rounded-lg" />
                    </a>
                    <p className="text-sm text-gray-500 mt-2">İdeal boyut: 1920x1080 piksel (Görsel için)</p>
                </>
            );
        } else if (['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension || '')) {
            // Video dosyaları için
            return (
                <>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <video controls className="max-w-full max-h-64 mx-auto rounded-lg">
                            <source src={media.url} type={`video/${fileExtension}`} />
                            Tarayıcınız bu videoyu desteklemiyor.
                        </video>
                    </a>
                    <p className="text-sm text-gray-500 mt-2">İdeal boyut: 1920x1080 piksel (Video için)</p>
                </>
            );
        } else if (['mp3', 'wav', 'flac'].includes(fileExtension || '')) {
            // Ses dosyaları için
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <audio controls className="w-full mx-auto rounded-lg">
                        <source src={media.url} type={`audio/${fileExtension}`} />
                        Tarayıcınız bu sesi desteklemiyor.
                    </audio>
                </a>
            );
        } else if (fileExtension === 'pdf') {
            // PDF dosyası için
            return (
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    PDF Dosyasını Görüntüle
                </a>
            );
        } else {
            // Desteklenmeyen dosya türü için
            return <p>Bu dosya tipi desteklenmiyor</p>;
        }
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-[#243757] text-sm font-normal mb-2">Medya</label>
                <div
                    className="relative w-full p-2 border border-[#dfe2e6] rounded-lg flex items-center justify-between cursor-pointer"
                    onClick={() => setIsMediaSelectorOpen(true)}  // Medya seçici popup'ını aç
                >
                    <span className="text-[#091E42] text-sm">{selectedMedia ? selectedMedia.key : 'S3 Medyası Seç'}</span>
                    <svg className="w-4 h-4 text-[#091E42]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>

                {/* Bilgilendirme mesajı */}
                {!selectedMedia && (
                    <p className="text-sm text-gray-500 mt-2">
                        Görseller için ideal boyut: 1824x918 piksel.
                    </p>
                )}

                {selectedMedia && (
                    <div className="mt-4">
                        {renderMediaPreview(selectedMedia)} {/* Seçilen medya dosyasını türüne göre göster */}
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700">URL</label>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => {
                        setUrl(e.target.value);
                        setError(''); // URL girildiğinde hata mesajı temizlenir
                    }}
                    className="w-full px-4 py-2 border rounded-md"
                    placeholder="Medya için yönlendirme URL'si"
                />
            </div>

            {error && <p className="text-red-500 text-sm">{error}</p>}

            <MediaSelector
                isOpen={isMediaSelectorOpen}
                onSelect={handleMediaSelect}
                onClose={() => setIsMediaSelectorOpen(false)}
            />

            <div className="flex justify-end space-x-4">
                <button onClick={handlePreview} className="px-4 py-2.5 bg-red-700 text-white rounded-lg shadow">
                    Önizle
                </button>
                <button onClick={handleSave} className="px-4 py-2.5 bg-[#fcfcfc] rounded-lg shadow border border-[#d6d6d6] text-[#353642] text-base font-normal">
                    Ekle
                </button>
            </div>

            {/* Pop-up Önizleme Modal */}
            {isPreviewOpen && (
                <div
                    className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
                    onClick={() => setIsPreviewOpen(false)} // Modal dışına tıklayınca kapanacak
                >
                    <div
                        className="relative w-full max-w-[100vw] h-[100vh] bg-white rounded-lg p-6 overflow-auto"
                        onClick={(e) => e.stopPropagation()} // Modal içeriğine tıklanınca kapanmayacak
                    >
                        <button
                            onClick={() => setIsPreviewOpen(false)}
                            className="absolute top-2 right-2 text-black-500 hover:text-black-700 transition-all"
                            style={{ background: 'transparent', border: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* LargeCardSection bileşeni ile önizleme */}
                        <div className="w-full h-full mx-auto flex justify-center items-center">
                            <LargeCardSection componentData={{ media: selectedMedia?.url || '', url }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LargeCardForm;
